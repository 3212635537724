/** @jsx jsx */
import Layout from "components/layout"
import SEO from "components/seo"
import { Link } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { NotFound } from "styles/svg"
import { Container, jsx } from "theme-ui"

const NotFoundPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <SEO />
      <Container
        as="main"
        sx={{
          display: "flex",
          py: 9,
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1 sx={{ fontSize: "8rem", mb: 6 }}>404</h1>
        <h3>{t("404description")}</h3>
        <Link
          sx={{
            "&:hover": {
              color: "primary",
            },
            textDecoration: "none",
            my: 7,
            fontWeight: "bold",
            fontSize: 5,
          }}
          to="/"
        >
          {t("Go back home")}
        </Link>
        <NotFound sx={{ maxHeight: "400px" }} />
      </Container>
    </Layout>
  )
}

export default NotFoundPage
